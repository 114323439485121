import React from 'react';
import './About.css';

function About() {
    return (
        <div style={{ marginTop: '150px' }}>
        </div>
    );
}

export default About;