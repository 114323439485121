import React from 'react';
import './ByRequest.css';

function ByRequest() {
    return (
        <div className='empty-collection'>

        </div>
    );
}

export default ByRequest;