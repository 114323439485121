export function FailIcon() {
  return (
    <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="Error">
      <path
        d="M23.214.586A2 2 0 0 0 21.798 0H10.202c-.53 0-1.04.21-1.414.586l-8.2 8.202A1.985 1.985 0 0 0 0 10.202V21.8c0 .53.21 1.04.586 1.414l8.2 8.202c.376.374.884.584 1.416.584H21.8c.53 0 1.04-.21 1.414-.586l8.2-8.202c.376-.374.586-.882.586-1.414V10.202c0-.53-.21-1.04-.586-1.414l-8.2-8.202zM30 21.798L21.798 30H10.202L2 21.798V10.202L10.202 2H21.8l8.2 8.202v11.596zM16 6a2 2 0 0 0-2 2v10a2 2 0 0 0 4 0V8a2 2 0 0 0-2-2zm-2 18a2 2 1080 1 0 4 0 2 2 1080 1 0-4 0z"
        fill="rgb(0, 0, 0, 0.8)"
      ></path>
    </svg>
  );
}





