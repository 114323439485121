import React from 'react';
import './Terms.css';

function Terms() {
  return (
    <div className='terms'>
      <h1>Terms & Conditions</h1>
      <p>The Terms of Use and Privacy Policy posted on the Site are an agreement that sets forth the terms and conditions that will govern your use of the Site, including your use of the Site to view items that are posted on the Site.  By electing to access or use the Site you agree to be bound by the Terms of Use and Privacy Policy.</p>
      <ul>
        <li>
        <p><strong>Access</strong>. (a) We reserve the right to withdraw or modify the Site and any content we provide on the Site, in our sole discretion without notice.  From time to time, we may restrict access to some parts of the Site to certain users. This Site is using your IP address compliance with the Terms of Use and Privacy Policy.  If you choose or are provided with a user name or password as part of our security procedures in order to access certain areas of the Site, you must treat such information as confidential, and you must not disclose it to any other person or entity. (b) Petrosian cannot guarantee continuous or secure access to the Site or our services provided by the Site.  Operation of the Site may be limited by many factors that are outside of the control of Petrosian.</p>
        </li>
        <li>
        <strong>Security</strong>. We follow reasonable standards to protect the personal information submitted to us, both during transmission and once we receive it.  However, no method of transmission over the Internet, or method of electronic storage, is completely secure.  Therefore, while we strive to use reasonable means to protect your personal information, we cannot guarantee its security.
        </li>
        <li>
        <strong>Site Content and Marks</strong>. You acknowledge and agree that Petrosian’s name and trademarks and other graphics, logos, slogans and service names used by Petrosian on the Site are owned by Petrosian, are protected by applicable trademark and other intellectual property rights and laws and you have no rights with respect thereto.  You also acknowledge and agree that all designs, concepts and content, including photographs, on the Site are owned by Petrosian and are protected by applicable copyright laws.  You acknowledge that any unauthorized use by you of any of the marks, designs, concepts or content on the Site is prohibited and any such use may result in civil and criminal penalties being imposed on you.
        </li>
        <li>
        <strong>Limited Licenses and Permissions</strong>. We grant you a limited license and permission to access and make personal use of the Site, but not to download any portion of it for any commercial purpose or to modify any portion of it.  You agree not to store in any form, distribute, transmit, display, reproduce, modify, create derivative works from, sell or otherwise exploit any of the content on the Site for any commercial purpose.  You may occasionally solely for personal, non-commercial use (i) print a single copy of pages of the Site or (ii) copy a picture or a description of a product from the Site on your blog or share a photograph or description of a product from the Site through use of your social media account, but you may not otherwise reproduce any material appearing on the Site without our prior written consent.  No right, title or interest in any of the content on the Site is granted to you by such copying.
        </li>
        <li>
        <strong>Products</strong>. While we have tried to actually display the colors of products, the actual colors you will see depend on your monitor and may not be accurate.  The prices displayed on the Site are quoted in U.S. dollars, unless otherwise indicated.
        </li>
        <li>
        <strong>Availability of Products on The Site</strong>. Merchandise availability on the Site is not guaranteed.  While we endeavor to keep the Site current, we do not guarantee that all items featured on the Site at any time remain available for purchase at the time you visit the Site, but If we haven't that model in that size at our store we will make it especially for you within 15 days.  You will receive a payment confirmation e-mail once and also once about shipment details.
        </li>
        <li>
        <strong>Payment Processors and Credit Transactions</strong>. We and our third party processors may rely upon any notice, purchase order or any other document which purports to have been transmitted by you or signed by you and we shall have no duty to make any inquiry or investigation. You authorize our third party processors to initiate credit transactions with respect to the purchase by you of products through use of the Site.  You will also be responsible for all taxes associated with your purchase.  If you fail to render payment as prescribed for any transaction, the transaction will not be completed.  Since the use of a credit card may be limited by your agreement with your credit card company and/or by applicable law, Petrosian is not liable to you if we do not complete a transaction as a result of any such limit, or if a credit card company fails to honor any credit to your account.  Our payment processors may post operating rules that you are required to comply with in order to use their services and such rules may be changed by a payment processor from time to time. Prior to making any purchase or other transaction on the Site you should review the terms of use and privacy policy on our payment processor’s site to make sure that you agree to such terms of use and privacy policy and, if you do not agree to such terms of use and privacy policy, you should not perform such transaction.
        </li>
        <li>
        <strong>Feedback and Comments</strong>. You may provide us feedback and comments with respect to the Site, but all such feedback, comments and submissions provided by you are owned by Petrosian and by your posting on the Site or sending or otherwise transmitting any feedback, comments or other submissions you hereby assign to Petrosian all worldwide rights, title and interest in such feedback, comments and submissions in all mediums and all forms without the payment by Petrosian of any compensation.  You acknowledge and agree that Petrosian shall not consider any of such feedback, comments and submissions as your confidential or proprietary information, irrespective of whether you mark or otherwise provide notice to Petrosian that such feedback, comments and submissions are your confidential or proprietary information.  You agree that you shall not provide any feedback, comments or other submissions that violate any applicable laws or regulations or that infringe or misappropriate any third party’s rights or that are libelous, obscene or violate a third party’s privacy rights. 
        </li>
        <li>
        <strong>Disclaimers of Warranties</strong>. THE SITE, ALL CONTENTS AND ALL PRODUCTS AND SERVICES ARE PROVIDED ON AN “AS IS” BASIS WITHOUT REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED.  ALTHOUGH PETROSIAN ENDEAVORS TO PROVIDE ACCURATE INFORMATION ON THE SITE, IT DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE ACCURACY OR RELIABILITY OF INFORMATION ON THE SITE.  PETROSIAN DOES NOT WARRANT THAT THE SITE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED OR THAT THE SITE IS FREE OF VIRUSES. YOUR USE OF THE SITE IS SOLELY AT YOUR OWN RISK.  TO THE FULLEST EXTENT NOT PROHIBITED BY APPLICABLE LAW, PETROSIAN HEREBY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE SITE AND THE CONTENT ON THE SITE, INCLUDING, BUT NOT LIMITED TO ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        </li>
        <li>
        <strong>Restriction on Your Use</strong>. (a) While using the Site, you will not: (i) violate any laws, third party rights, or our policies set forth on the Site; (ii) use any content (including, without limitation, all designs and photographs) on the Site for any commercial purposes; (iii) post false, inaccurate, misleading, defamatory, or libelous content (including personal information); (iv) distribute or post spam, chain letters, or pyramid schemes on or through the Site; or (v) impair, disable or otherwise adversely affect the Site or the functionality or operation of the Site, including without limitation, distributing, submitting, posting or uploading any materials that contain or distribute viruses or disabling code, files, programs or any other technologies that may harm Petrosian, the Site or any other Site user. (b) In addition, you will not (i) harvest or otherwise collect (i.a) information, designs or content provided on the Site or (i.b) information with respect to any Site’s users, including e-mail addresses; (ii) provide any links, frames or tags to or of any content on the Site (including, without limitation, designs and photographs) without our express written consent, except that you may on an occasional basis for non-commercial purposes only provide a link to a photograph or a description of a product from the Site to your blog or share a photograph or description of a product from the Site through use of your social media account; or (iii) use any spiders, automated programs, scripts, robots, software or any similar devices that obtain, copy or link to content or information on the Site or with respect to the Site’s users, or affect the operation of the Site.
        </li>
        <li>
        <strong>Indemnity</strong>. As a condition of use of the Site, you agree to indemnify and hold harmless Petrosian, its third party vendors and its affiliates and their officers, members, managers, directors, agents and employees against any and all liabilities, expenses (including, without limitation, attorneys’ fees and court costs) and damages arising out of or relating to any and all direct or indirect claims resulting from or relating to your breach of any provision of the Terms of Use or the Privacy Policy.
        </li>
        <li>
        <strong>Limitation of Liability</strong>. UNDER NO CIRCUMSTANCES SHALL PETROSIAN, ITS AFFILIATES, DIRECTORS, OFFICERS, MEMBERS AND MANAGERS OR ITS THIRD PARTY VENDORS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES WITH RESPECT TO OR THAT ARISE OUT OF THE USE OF THE SITE, THE SERVICES OR PRODUCTS PROVIDED BY PETROSIAN, THE INABILITY TO USE THE SITE OR OTHERWISE.  THIS LIMITATION APPLIES WHETHER THE LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER BASIS, EVEN IF PETROSIAN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN UNDER CIRCUMSTANCES THAT CAUSE ANY REMEDY TO FAIL OF ITS ESSENTIAL PURPOSE.  IN NO EVENT WILL OUR DAMAGES TO YOU, REGARDLESS OF THE BASIS FOR THE UNDERLYING CLAIM, EXCEED THE AMOUNT YOU HAVE PAID FOR PURCHASES ON THE SITE DURING THE SIX (6) MONTH PERIOD PRIOR TO BRINGING SUCH CLAIM.
        </li>
        <li>
        <strong>Links to other Sites</strong>. The Site may provide or contain links to other web sites.  Petrosian makes no representations or warranties as to the goods or services offered through those sites, and by having a link on the Site to those sites, we are not hereby endorsing the goods or services offered through those sites.  You access such linked sites at your own risk and by accessing them you leave the Petrosian Site.  Such linked sites are not under our control and we are not responsible for the contents of such sites or the terms of use and privacy practices of such sites.
        </li>
        <li>
        <strong>Site Policies, Modification, and Severability</strong>. The Terms of Use, Privacy Policy and the other policies posted on the Site govern your use of the Site.  We reserve the right to make changes to the Site, policies, the Terms of Use and the Privacy Policy at any time.  You agree that you are responsible to review the Terms of Use and Privacy Policy each time you access the Site.  Once posted on the Site, revised terms become effective two (2) days after posting.  If any of the terms of the Terms of Use or the Privacy Policy shall be deemed invalid, void, or for any reason unenforceable, that term shall be deemed severable and shall not affect the validity and enforceability of any remaining term.
        </li>
        <li>
        <strong>Entire Agreement</strong>. The Terms of Use and the Privacy Policy constitutes the entire agreement between you and Petrosian with respect to your use of the Site (additional terms apply with respect to purchases on the Site) and supersede all prior or contemporaneous communications, promises and proposals, whether oral, written or electronic, between you and Petrosian with respect to the Site.
        </li>
        <li>
        <strong>Price Adjustments</strong>. Price adjustments may not be applied retroactively to Petrosian orders.
        </li>
      </ul>
      <p><strong>Copyright ©2021 Petrosian Brand, Inc.</strong> and other logos or trademarks listed herein are trademarks of <strong>Petrosian Brand, Inc. All rights reserved.</strong></p>
    </div>
  );
}

export default Terms;